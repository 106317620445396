function onClickMobileMenuButton(id) {
  if (id === 'mobile-menu-open-btn') {
    document.getElementById('mobile-menu-panel').classList.add('active');
    document.getElementById('mobile-menu-open-btn').classList.add('hidden');
    document.getElementById('mobile-menu-close-btn').classList.remove('hidden');
    document.getElementsByClassName('scroll-top-button')[0].classList.add('hidden');
  } else {
    document.getElementById('mobile-menu-panel').classList.remove('active');
    document.getElementById('mobile-menu-open-btn').classList.remove('hidden');
    document.getElementById('mobile-menu-close-btn').classList.add('hidden');
    document.getElementsByClassName('scroll-top-button')[0].classList.remove('hidden');
  }
}

function onClickFacetSettings(name, settingsId, iconId) {
  var el = document.getElementById(settingsId);
  var imgEl = document.getElementById(iconId);

  var OPEN_LABEL = name + ' Facetteneinstellungen öffnen';
  var CLOSE_LABEL = name + ' Facetteneinstellungen schließen';

  function clickHandler(event) {
    if (event.target.closest('.facets--settings')) {
      return;
    }
    document.removeEventListener('click', clickHandler, true);
    el.classList.add('hidden');
    imgEl.setAttribute('alt', OPEN_LABEL);
  }

  el.classList.toggle('hidden');

  if (el.classList.contains('hidden')) {
    document.removeEventListener('click', clickHandler, true);
    imgEl.setAttribute('alt', OPEN_LABEL);
  } else {
    document.addEventListener('click', clickHandler, true);
    imgEl.setAttribute('alt', CLOSE_LABEL);
  }
}

function jsIsMinifiedFacet(facetId) {
  var cookieName = 'JMBOS_Minify';
  var Cookies = window.Cookies;
  var minCookie = Cookies.get(cookieName);
  if (minCookie && minCookie.includes(facetId)) {
    return true;
  }
  return false;
}

function onMinimizeFacet(filterName, contentId, settingsId) {
  document.getElementById(contentId).classList.toggle('hidden');
  document.getElementById(settingsId).classList.add('hidden');

  // toggle text between 'minimieren' and 'maximieren'
  var btn = document
    .getElementById(settingsId)
    .getElementsByTagName('button')[0];
  btn.getElementsByTagName('span')[0].classList.toggle('hidden');
  btn.getElementsByTagName('span')[1].classList.toggle('hidden');

  var cookieName = 'JMBOS_min';
  var Cookies = window.Cookies;
  var minCookie = Cookies.get(cookieName);

  var expiry = { expires: 30 };
  var value = '';

  if (minCookie) {
    if (minCookie.includes(filterName)) {
      var arr = minCookie.split(',');
      arr.forEach(function (f) {
        if (f !== filterName && f.trim() != '') {
          value = f + ',' + value;
        }
      });
    } else {
      value = filterName + ',' + minCookie;
    }

    Cookies.remove(cookieName);
  } else {
    value = filterName;
  }
  Cookies.set(cookieName, value, expiry);
}


function onClickMailTemplate(textTemplate) {
  document.getElementById("mailMessage").value = textTemplate;
}


function onClickAcceptCookies(settingsId) {
  document.getElementById('cookies-consent').classList.add('hidden');

  var cookieName = 'JMBOS_User_Accepted_Cookies';
  var Cookies = window.Cookies;

  var expiry = { expires: 30 };
  var value = 'TRUE';
  Cookies.set(cookieName, value, expiry);
}

function showCookiesBanner() {
  var cookieName = 'JMBOS_User_Accepted_Cookies';
  var cookie = window.Cookies.get(cookieName);
  if (!cookie) {
    document.getElementById('cookies-consent').classList.remove('hidden');
  }
}

// on document ready
document.addEventListener('DOMContentLoaded', function () {
  showCookiesBanner();
}, false);


function onClickCheckboxIcon(inputId) {
 var iconElem = document.getElementById(inputId + '-icon');
 var inputElem = document.getElementById(inputId);

 var iconOnSrc = "/images/icons/icon-check-on.svg";
 var iconOffSrc = "/images/icons/icon-check-off.svg";

 if (inputElem.checked) {
   inputElem.checked = false;
   iconElem.setAttribute('src', iconOffSrc);
 } else {
   inputElem.checked = true;
   iconElem.setAttribute('src', iconOnSrc);
 }
}

function onClickRadioButton(field, inputId) {
 var iconOnSrc = "/images/icons/icon-radio-on.svg";
 var iconOffSrc = "/images/icons/icon-radio-off.svg";

 var radios = document.getElementsByName(field);
 
 for (var i=0; i<radios.length; i++) {
   var iconElem = document.getElementById(radios[i].id+'-icon');
   if (radios[i].id === inputId) {
     radios[i].checked = true;
     iconElem.setAttribute('src', iconOnSrc);
   } else {
     radios[i].checked = false;
     iconElem.setAttribute('src', iconOffSrc);
   }
 }
}
